"use client";
import {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  PropsWithChildren,
} from "react";
import { BrowserProvider } from "ethers";
import { ActionType, NewChainData } from "./types";
import web3ContextReducer from "./reducer";
import { Web3StateContext, Web3ActionsContext } from "./context";
import { initialState } from "./constants";
import {
  cacheConnector,
  clearCachedConnector,
  getCachedConnector,
} from "./connectorCache";
import { Connector } from "../connectors/types";
import { Blockchain } from "@/shared/config/blockchain/types";
import { resolveChainID } from "@/shared/config/blockchain/helpers";
import useLogout from "@/app/(components)/Application/Login/hooks/useLogout";
import { useQueryClient } from "@tanstack/react-query";
import { verifyToken } from "@/api/auth";

const Web3ContextProvider = ({
  children,
  network,
}: PropsWithChildren & { network: Blockchain }) => {
  const [state, dispatch] = useReducer(web3ContextReducer, {
    ...initialState,
    chainId: resolveChainID(network),
    network: network || Blockchain.ETHEREUM,
  });
  const queryClient = useQueryClient();

  const onLogout = useCallback(() => {
    queryClient.removeQueries(["token-address-check"]);
    queryClient.removeQueries(["token-check"]);
    queryClient.invalidateQueries(["me"]);
  }, [queryClient]);

  const logout = useLogout(onLogout);
  const subscribeToEthereumProviderEvents = useCallback(
    (provider: any, web3: BrowserProvider) => {
      if (!provider.on) {
        return;
      }

      const onAccountsChanges = async (accounts: string[]) => {
        const selectedAddress = accounts[0];
        const verificationResponse = await verifyToken();

        if (selectedAddress) {
          if (state.currentAddress !== selectedAddress) {
            await logout();
          }

          dispatch({
            type: ActionType.ACCOUNT_CHANGED,
            payload: {
              currentAddress: accounts[0],
              signer: await web3.getSigner(),
              isAccountChangedPopupVisible: verificationResponse.valid,
            },
          });
        } else {
          dispatch({ type: ActionType.DISCONNECTED });
          clearCachedConnector();
        }
      };

      const onChainChanged = (chainId: string) => {
        dispatch({ type: ActionType.CHAIN_CHANGED, payload: { chainId } });
      };

      const onDisconnect = ({ code }: { code: number }) => {
        switch (code) {
          case 1013: {
            return;
          }
          case 1011: {
            window.location.reload();
            return;
          }
          default: {
            dispatch({ type: ActionType.DISCONNECTED });
            clearCachedConnector();
          }
        }
      };

      provider.on("accountsChanged", onAccountsChanges);
      provider.on("chainChanged", onChainChanged);
      provider.on("disconnect", onDisconnect);

      return () => {
        provider.removeListener("accountsChanged", onAccountsChanges);
        provider.removeListener("chainChanged", onChainChanged);
        provider.removeListener("disconnect", onDisconnect);
      };
    },
    [logout, state.currentAddress],
  );

  const disconnect = useCallback(async () => {
    if (state.web3?.provider?.close) {
      await state.web3.provider.close();
    }
    clearCachedConnector();
    dispatch({
      type: ActionType.DISCONNECTED,
    });
  }, [state.web3]);

  const switchChain = useCallback(
    async (chainId: string) => {
      await state.web3.provider.send("wallet_switchEthereumChain", [
        { chainId: chainId },
      ]);
    },
    [state.web3],
  );

  const addChain = useCallback(
    async (chainData: NewChainData) => {
      await state.web3.provider.send("wallet_addEthereumChain", [chainData]);
    },
    [state.web3],
  );

  const connectToEthereum = useCallback(
    async (connector: Connector) => {
      try {
        const provider = await connector.connect(state.network as Blockchain);

        if (!provider) {
          return;
        }

        cacheConnector(connector);
        const web3 = new BrowserProvider(provider, "any");
        const signer = await web3.getSigner();
        const currentAddress = (await signer.getAddress()).toLowerCase();
        const network = await web3.getNetwork();
        const chainId = network.chainId.toString();
        subscribeToEthereumProviderEvents(provider, web3);
        dispatch({
          type: ActionType.CONNECTED,
          payload: {
            web3,
            chainId,
            currentAddress,
            signer,
          },
        });

        return { currentAddress, signer };
      } catch (err: any) {
        clearCachedConnector();
        dispatch({
          type: ActionType.DISCONNECTED,
        });
        throw new Error(err);
      }
    },
    [state.network, subscribeToEthereumProviderEvents],
  );

  const toggleWalletPicker = useCallback(() => {
    dispatch({ type: ActionType.WALLET_PICKER_TOGGLE });
  }, []);

  const toggleNetworkMismatch = useCallback(
    (onSuccessfulNetworkChange?: () => any) => {
      dispatch({
        type: ActionType.NETWORK_MISMATCH_TOGGLE,
        payload: { onSuccessfulNetworkChange },
      });
    },
    [],
  );

  const hideAccountChangePopup = useCallback(() => {
    dispatch({
      type: ActionType.HIDE_ACCOUNT_CHANGE_POPUP,
    });
  }, []);

  const switchBlockchain = useCallback((blockchain: Blockchain) => {
    dispatch({ type: ActionType.BLOCKCHAIN_CHANGED, payload: { blockchain } });
  }, []);

  useEffect(() => {
    setTimeout(async () => {
      const cachedConnector = getCachedConnector();
      if (cachedConnector) {
        const isConnectorUnlocked =
          !cachedConnector.isAuthorized ||
          (await cachedConnector.isAuthorized());

        if (!isConnectorUnlocked) {
          return;
        }

        clearCachedConnector();
        connectToEthereum(cachedConnector);
      }
    }, 100);
  }, [connectToEthereum]);

  const actions = useMemo(
    () => ({
      connectToEthereum,
      disconnect,
      switchChain,
      addChain,
      toggleWalletPicker,
      toggleNetworkMismatch,
      switchBlockchain,
      hideAccountChangePopup,
    }),
    [
      connectToEthereum,
      disconnect,
      switchChain,
      addChain,
      toggleWalletPicker,
      toggleNetworkMismatch,
      switchBlockchain,
      hideAccountChangePopup,
    ],
  );

  return (
    <Web3StateContext.Provider value={state}>
      <Web3ActionsContext.Provider value={actions}>
        {children}
      </Web3ActionsContext.Provider>
    </Web3StateContext.Provider>
  );
};

export default Web3ContextProvider;
