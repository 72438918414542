import { Action, ActionType, Web3ContextState } from "./types";
import { initialState } from "./constants";
import {
  resolveChainID,
  resolveChainRPCUrl,
} from "@/shared/config/blockchain/helpers";
import { JsonRpcProvider } from "ethers";

const web3ContextReducer = (state: Web3ContextState, action: Action) => {
  switch (action.type) {
    case ActionType.CONNECTED:
      return {
        ...state,
        web3: action.payload.web3,
        signer: action.payload.signer,
        currentAddress: action.payload.currentAddress,
        chainId: action.payload.chainId,
        isConnectionLoading: false,
        isCorrectChain:
          +action.payload.chainId === resolveChainID(state.network),
      };
    case ActionType.CHAIN_CHANGED:
      return {
        ...state,
        chainId: action.payload.chainId,
        isCorrectChain:
          +action.payload.chainId === resolveChainID(state.network),
        isNetworkMismatchPopupVisible:
          state.isNetworkMismatchPopupVisible &&
          +action.payload.chainId === resolveChainID(state.network),
      };
    case ActionType.ACCOUNT_CHANGED: {
      return {
        ...state,
        currentAddress: action.payload.currentAddress,
        signer: action.payload.signer,
        isAccountChangedPopupVisible:
          action.payload.isAccountChangedPopupVisible,
      };
    }
    case ActionType.DISCONNECTED:
      return {
        ...initialState,
        isConnectionLoading: false,
        isWalletPickerOpen: state.isWalletPickerOpen,
      };
    case ActionType.WALLET_PICKER_TOGGLE:
      return {
        ...state,
        isWalletPickerOpen: !state.isWalletPickerOpen,
      };
    case ActionType.NETWORK_MISMATCH_TOGGLE:
      return {
        ...state,
        onSuccessfulNetworkChange: !state.isNetworkMismatchPopupVisible
          ? action.payload.onSuccessfulNetworkChange
          : null,
        isNetworkMismatchPopupVisible: !state.isNetworkMismatchPopupVisible,
      };
    case ActionType.BLOCKCHAIN_CHANGED:
      return {
        ...state,
        isCorrectChain:
          +resolveChainID(action.payload.blockchain)! === +state.chainId!,
        network: action.payload.blockchain,
        readOnlyProvider: new JsonRpcProvider(
          resolveChainRPCUrl(action.payload.blockchain),
        ),
      };
    case ActionType.HIDE_ACCOUNT_CHANGE_POPUP:
      return {
        ...state,
        isAccountChangedPopupVisible: false,
      };
    default:
      return state;
  }
};

export default web3ContextReducer;
