import styles from "./BlockchainSwitcher.module.css";
import { twMerge } from "tailwind-merge";
import { publicConfig } from "@/shared/config/constants";
import { getBlockchainIcon } from "@/shared/constants";

export const BLOCKCHAINS_MENU = Object.values(publicConfig.chains).map(
  (chain) => ({
    id: chain.name,
    label: chain.label,
    icon: getBlockchainIcon(chain.name, twMerge(styles.ethIcon, "w-6 h-6")),
  }),
);
